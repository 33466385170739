<script setup lang="ts">
import { MAPPED_ROUTE_NAMES, ROUTE_BLOG, ROUTE_FAQ, ROUTE_FOOD_NUTRITION, ROUTE_GIFT_CARDS, ROUTE_HOW_IT_WORKS, ROUTE_LOGIN, ROUTE_MAIN, ROUTE_MENUS, ROUTE_PLANS } from "@/constants/routes";
import {useStaticHeader} from "~/composables/useStaticHeader";

const { isRouteDisallowed } = useRouteValidator();
const authStore = useAuthStore();
const route = useRoute();
const isMenuOpen = ref(false);

const toggleMenu = () => {
  isMenuOpen.value = !isMenuOpen.value;
};

const closeMenu = () => {
  isMenuOpen.value = false;
};

watch(
  () => route.path,
  () => {
    isMenuOpen.value = false;
  }
);

useStaticHeader('.promo-banner', '.lg-top-header-style');
</script>

<template>
  <div class="tw-sticky tw-top-0 tw-right-0 tw-left-0 tw-z-50 lg-top-header-style" style="--rh: -69px">
    <header
      id="header"
      class="tw-bg-nl-yellow-100 tw-shadow-nl-3 tw-pt-[6px] tw-pb-2 lg:tw-py-[5px] tw-relative after:tw-content-[''] after:tw-fixed after:tw-inset-x-0 after:tw-top-0 after:tw-h-[100vh] after:tw-bg-white/[.61] after:tw-pointer-events-none after:tw-opacity-0 lg:after:tw-content-none after:tw-ease-in after:tw-duration-300"
    >
      <div class="tw-pl-[11px] tw-pr-[15px] md:tw-px-[33px]">
        <div class="tw-flex tw-items-center tw-justify-between tw-min-h-[48px]">
          <div
            class="tw-w-[162px] lg:tw-w-[160px] tw-text-[0] tw-mr-4 lg:tw-mr-10"
          >
            <nuxt-link :to="ROUTE_MAIN"
              ><img
                src="/assets/images/signup/logo.svg"
                alt="nurture-life"
                aria-hidden="true"
            /></nuxt-link>
          </div>
          <div
            :class="{
              'tw-translate-x-0': isMenuOpen,
              'max-[1023px]:tw-translate-x-full': !isMenuOpen,
            }"
            class="max-[1023px]:tw-flex max-[1023px]:tw-flex-col max-[1023px]:tw-justify-between lg:tw-flex-1 max-[1023px]:tw-fixed max-[1023px]:tw-w-[204px] max-[1023px]:tw-h-[calc(var(--vh))] max-[1023px]:tw-bg-nl-yellow-100 max-[1023px]:tw-right-0 max-[1023px]:tw-top-0 max-[1023px]:tw-bottom-0 max-[1023px]:tw-pt-12 max-[1023px]:tw-px-[26px] max-[1023px]:tw-pb-[22px] tw-z-2 tw-ease-in tw-duration-300"
          >
            <button
              @click="closeMenu"
              class="icon-main-times tw-absolute tw-top-[18px] tw-right-4 tw-text-[13px] lg:tw-hidden"
            ></button>
            <nav
              class="tw-text-[23px] lg:tw-text-[18px] tw-leading-[1.2] tw-font-medium nl-nav"
            >
              <ul
                class="tw-flex max-[1023px]:tw-flex-col tw-gap-[22px] lg:tw-gap-[31px] max-[1023px]:tw-text-center"
              >
                <li class="md:tw-hidden">
                  <nuxt-link
                    title="Home"
                    :to="ROUTE_MAIN"
                    class="tw-text-nl-green-100 lg:hover:tw-text-nl-green-400 lg:focus:tw-text-nl-green-400 tw-group hover:max-[1023px]:tw-font-bold"
                    ><span
                      class="tw-inline-block tw-relative before:tw-content-[''] lg:before:tw-content-none before:tw-absolute before:-tw-left-[25px] before:tw-top-1/2 before:tw-transform before:-tw-translate-1/2 before:tw-w-[13px] before:tw-h-[3px] before:tw-rounded-[10px] before:tw-bg-nl-green-100 before:tw-opacity-0 before:tw-invisible group-hover:before:tw-opacity-100 group-hover:before:tw-visible after:tw-content-[''] lg:after:tw-content-none after:tw-absolute after:-tw-right-[25px] after:tw-top-1/2 after:tw-transform after:-tw-translate-1/2 after:tw-w-[13px] after:tw-h-[3px] after:tw-rounded-[10px] after:tw-bg-nl-green-100 after:tw-opacity-0 after:tw-invisible group-hover:after:tw-opacity-100 group-hover:after:tw-visible tw-transition-all"
                      >Home</span
                    ></nuxt-link
                  >
                </li>
                <li>
                  <nuxt-link
                    title="Menus"
                    :to="ROUTE_MENUS"
                    class="tw-text-nl-green-100 lg:hover:tw-text-nl-green-400 lg:focus:tw-text-nl-green-400 tw-group hover:max-[1023px]:tw-font-bold"
                    ><span
                      class="tw-inline-block tw-relative before:tw-content-[''] lg:before:tw-content-none before:tw-absolute before:-tw-left-[25px] before:tw-top-1/2 before:tw-transform before:-tw-translate-1/2 before:tw-w-[13px] before:tw-h-[3px] before:tw-rounded-[10px] before:tw-bg-nl-green-100 before:tw-opacity-0 before:tw-invisible group-hover:before:tw-opacity-100 group-hover:before:tw-visible after:tw-content-[''] lg:after:tw-content-none after:tw-absolute after:-tw-right-[25px] after:tw-top-1/2 after:tw-transform after:-tw-translate-1/2 after:tw-w-[13px] after:tw-h-[3px] after:tw-rounded-[10px] after:tw-bg-nl-green-100 after:tw-opacity-0 after:tw-invisible group-hover:after:tw-opacity-100 group-hover:after:tw-visible tw-transition-all"
                      >Menus</span
                    ></nuxt-link
                  >
                </li>
                <li>
                  <nuxt-link
                    title="Plans"
                    :to="ROUTE_PLANS"
                    class="tw-text-nl-green-100 lg:hover:tw-text-nl-green-400 lg:focus:tw-text-nl-green-400 tw-group hover:max-[1023px]:tw-font-bold"
                    ><span
                      class="tw-inline-block tw-relative before:tw-content-[''] lg:before:tw-content-none before:tw-absolute before:-tw-left-[25px] before:tw-top-1/2 before:tw-transform before:-tw-translate-1/2 before:tw-w-[13px] before:tw-h-[3px] before:tw-rounded-[10px] before:tw-bg-nl-green-100 before:tw-opacity-0 before:tw-invisible group-hover:before:tw-opacity-100 group-hover:before:tw-visible after:tw-content-[''] lg:after:tw-content-none after:tw-absolute after:-tw-right-[25px] after:tw-top-1/2 after:tw-transform after:-tw-translate-1/2 after:tw-w-[13px] after:tw-h-[3px] after:tw-rounded-[10px] after:tw-bg-nl-green-100 after:tw-opacity-0 after:tw-invisible group-hover:after:tw-opacity-100 group-hover:after:tw-visible tw-transition-all"
                      >Plans</span
                    ></nuxt-link
                  >
                </li>
                <li>
                  <nuxt-link
                    title="How it Works"
                    :to="ROUTE_HOW_IT_WORKS"
                    class="tw-text-nl-green-100 lg:hover:tw-text-nl-green-400 lg:focus:tw-text-nl-green-400 tw-group hover:max-[1023px]:tw-font-bold"
                    ><span
                      class="tw-inline-block tw-relative before:tw-content-[''] lg:before:tw-content-none before:tw-absolute before:-tw-left-[25px] before:tw-top-1/2 before:tw-transform before:-tw-translate-1/2 before:tw-w-[13px] before:tw-h-[3px] before:tw-rounded-[10px] before:tw-bg-nl-green-100 before:tw-opacity-0 before:tw-invisible group-hover:before:tw-opacity-100 group-hover:before:tw-visible after:tw-content-[''] lg:after:tw-content-none after:tw-absolute after:-tw-right-[25px] after:tw-top-1/2 after:tw-transform after:-tw-translate-1/2 after:tw-w-[13px] after:tw-h-[3px] after:tw-rounded-[10px] after:tw-bg-nl-green-100 after:tw-opacity-0 after:tw-invisible group-hover:after:tw-opacity-100 group-hover:after:tw-visible tw-transition-all"
                      >How it Works</span
                    ></nuxt-link
                  >
                </li>
                <li>
                  <nuxt-link
                    title="Nutrition"
                    :to="ROUTE_FOOD_NUTRITION"
                    class="tw-text-nl-green-100 lg:hover:tw-text-nl-green-400 lg:focus:tw-text-nl-green-400 tw-group hover:max-[1023px]:tw-font-bold"
                    ><span
                      class="tw-inline-block tw-relative before:tw-content-[''] lg:before:tw-content-none before:tw-absolute before:-tw-left-[25px] before:tw-top-1/2 before:tw-transform before:-tw-translate-1/2 before:tw-w-[13px] before:tw-h-[3px] before:tw-rounded-[10px] before:tw-bg-nl-green-100 before:tw-opacity-0 before:tw-invisible group-hover:before:tw-opacity-100 group-hover:before:tw-visible after:tw-content-[''] lg:after:tw-content-none after:tw-absolute after:-tw-right-[25px] after:tw-top-1/2 after:tw-transform after:-tw-translate-1/2 after:tw-w-[13px] after:tw-h-[3px] after:tw-rounded-[10px] after:tw-bg-nl-green-100 after:tw-opacity-0 after:tw-invisible group-hover:after:tw-opacity-100 group-hover:after:tw-visible tw-transition-all"
                      >Nutrition</span
                    ></nuxt-link
                  >
                </li>
                <li>
                  <nuxt-link
                    title="Gifts"
                    :to="ROUTE_GIFT_CARDS"
                    class="tw-text-nl-green-100 lg:hover:tw-text-nl-green-400 lg:focus:tw-text-nl-green-400 tw-group hover:max-[1023px]:tw-font-bold"
                    ><span
                      class="tw-inline-block tw-relative before:tw-content-[''] lg:before:tw-content-none before:tw-absolute before:-tw-left-[25px] before:tw-top-1/2 before:tw-transform before:-tw-translate-1/2 before:tw-w-[13px] before:tw-h-[3px] before:tw-rounded-[10px] before:tw-bg-nl-green-100 before:tw-opacity-0 before:tw-invisible group-hover:before:tw-opacity-100 group-hover:before:tw-visible after:tw-content-[''] lg:after:tw-content-none after:tw-absolute after:-tw-right-[25px] after:tw-top-1/2 after:tw-transform after:-tw-translate-1/2 after:tw-w-[13px] after:tw-h-[3px] after:tw-rounded-[10px] after:tw-bg-nl-green-100 after:tw-opacity-0 after:tw-invisible group-hover:after:tw-opacity-100 group-hover:after:tw-visible tw-transition-all"
                      >Gift Cards</span
                    ></nuxt-link
                  >
                </li>
                <li>
                  <nuxt-link
                    title="Blog"
                    :to="ROUTE_BLOG"
                    class="tw-text-nl-green-100 lg:hover:tw-text-nl-green-400 lg:focus:tw-text-nl-green-400 tw-group hover:max-[1023px]:tw-font-bold"
                    ><span
                      class="tw-inline-block tw-relative before:tw-content-[''] lg:before:tw-content-none before:tw-absolute before:-tw-left-[25px] before:tw-top-1/2 before:tw-transform before:-tw-translate-1/2 before:tw-w-[13px] before:tw-h-[3px] before:tw-rounded-[10px] before:tw-bg-nl-green-100 before:tw-opacity-0 before:tw-invisible group-hover:before:tw-opacity-100 group-hover:before:tw-visible after:tw-content-[''] lg:after:tw-content-none after:tw-absolute after:-tw-right-[25px] after:tw-top-1/2 after:tw-transform after:-tw-translate-1/2 after:tw-w-[13px] after:tw-h-[3px] after:tw-rounded-[10px] after:tw-bg-nl-green-100 after:tw-opacity-0 after:tw-invisible group-hover:after:tw-opacity-100 group-hover:after:tw-visible tw-transition-all"
                      >Blog</span
                    ></nuxt-link
                  >
                </li>
              </ul>
              <nuxt-link
                to="#"
                class="btn tw-min-w-0 tw-py-[10px] tw-px-3 tw-text-[16px] tw-hidden tw-mt-5"
                >See Menu &amp; Pricing</nuxt-link
              >
            </nav>
            <ul
              class="lg:tw-hidden tw-text-right tw-text-[16px] tw-leading-none tw-font-medium tw-flex tw-flex-wrap tw-gap-y-4"
            >
              <li class="tw-w-full">
                <nuxt-link
                  :href="ROUTE_FAQ"
                  target="_blank"
                  class="tw-text-nl-green-100 tw-transition-colors hover:max-[1023px]:tw-font-bold tw-group"
                >
                  <span
                    class="tw-inline-block tw-relative before:tw-content-[''] lg:before:tw-content-none before:tw-absolute before:-tw-left-[19px] before:tw-top-1/2 before:tw-transform before:-tw-translate-1/2 before:tw-w-[13px] before:tw-h-[3px] before:tw-rounded-[10px] before:tw-bg-nl-green-100 before:tw-opacity-0 before:tw-invisible group-hover:before:tw-opacity-100 group-hover:before:tw-visible after:tw-content-[''] lg:after:tw-content-none after:tw-absolute after:-tw-right-[19px] after:tw-top-1/2 after:tw-transform after:-tw-translate-1/2 after:tw-w-[13px] after:tw-h-[3px] after:tw-rounded-[10px] after:tw-bg-nl-green-100 after:tw-opacity-0 after:tw-invisible group-hover:after:tw-opacity-100 group-hover:after:tw-visible tw-transition-all"
                    >FAQ</span
                  >
                </nuxt-link>
              </li>
              <li class="tw-w-full">
                <nuxt-link
                  :to="ROUTE_LOGIN"
                  class="tw-text-nl-green-100 tw-transition-colors hover:max-[1023px]:tw-font-bold tw-group"
                  ><span
                    v-if="!authStore.isLoggedIn"
                    class="tw-inline-block tw-relative before:tw-content-[''] lg:before:tw-content-none before:tw-absolute before:-tw-left-[19px] before:tw-top-1/2 before:tw-transform before:-tw-translate-1/2 before:tw-w-[13px] before:tw-h-[3px] before:tw-rounded-[10px] before:tw-bg-nl-green-100 before:tw-opacity-0 before:tw-invisible group-hover:before:tw-opacity-100 group-hover:before:tw-visible after:tw-content-[''] lg:after:tw-content-none after:tw-absolute after:-tw-right-[19px] after:tw-top-1/2 after:tw-transform after:-tw-translate-1/2 after:tw-w-[13px] after:tw-h-[3px] after:tw-rounded-[10px] after:tw-bg-nl-green-100 after:tw-opacity-0 after:tw-invisible group-hover:after:tw-opacity-100 group-hover:after:tw-visible tw-transition-all"
                    >Log In</span
                  >
                </nuxt-link>
              </li>
            </ul>
          </div>
          <div
            class="tw-flex tw-items-center tw-flex-row-reverse lg:tw-flex-row tw-gap-x-3 lg:tw-gap-x-6 tw-relative tw-z-1 tw-pr-[50px] lg:tw-pr-2"
          >
            <nuxt-link
              v-if="isRouteDisallowed([MAPPED_ROUTE_NAMES.LOGIN])"
              :to="ROUTE_LOGIN"
              class="tw-text-nl-green-100 hover:tw-text-nl-green-400 focus:tw-text-nl-green-400 tw-transition-colors tw-text-[19px] lg:tw-text-[18px]"
              ><i class="icon-main-user-bold tw-block lg:tw-hidden"></i>
              <span
                class="max-lg:tw-hidden tw-font-bold"
                v-if="!authStore.isLoggedIn"
                >LOG IN</span
              >
              <i
                v-else
                class="icon-main-user-bold tw-block max-lg:tw-hidden"
              ></i>
            </nuxt-link>
            <nuxt-link
              v-if="isRouteDisallowed([MAPPED_ROUTE_NAMES.LOGIN])"
              :href="ROUTE_MENUS"
              class="btn tw-min-w-0 tw-py-2 tw-px-2 lg:tw-pt-[8px] lg:tw-pb-[10px] lg:tw-px-3 tw-text-[13px] lg:tw-text-[18px] tw-hidden lg:tw-inline"
              >See Menu &amp; Pricing</nuxt-link
            >
            <nuxt-link
              v-if="isRouteDisallowed([MAPPED_ROUTE_NAMES.LOGIN])"
              :href="ROUTE_MENUS"
              class="btn tw-min-w-0 tw-py-2 tw-px-2 lg:tw-pt-[8px] lg:tw-pb-[10px] lg:tw-px-3 tw-text-[13px] lg:tw-text-[18px] tw-inline lg:tw-hidden"
              >See Menu</nuxt-link
            >
            <button
              @click="toggleMenu"
              class="lg:tw-hidden icon-main-menu tw-absolute tw-right-0 tw-top-1/2 tw-transform -tw-translate-y-1/2 tw-text-[20px] tw-text-nl-green-100 hover:tw-text-nl-green-400 tw-transition-colors"
            >
              <span class="sr-only">Menu Opener</span>
            </button>
          </div>
        </div>
      </div>
    </header>
    <span
      @click="closeMenu"
      :class="[
        'tw-fixed tw-inset-0 tw-bg-nl-green-100/35 tw-transition-[opacity,visibility] lg:tw-hidden',
        isMenuOpen ? 'tw-opacity-100 tw-visible' : 'tw-opacity-0 tw-invisible',
      ]"
    ></span>
  </div>
</template>

<style scoped>
@import url("~/assets/css/app.css");
.lg-top-header-style {
  --rh: 0px;
  top: calc(var(--rh, 0px));
}
</style>
