import axios from "axios";

export default defineNuxtPlugin((nuxtApp) => {
    if (process.client) {
      // Handle error states
      nuxtApp.vueApp.config.errorHandler = (error: any) => {
        if (axios.isCancel(error)) {
          return; // Ignore cancelled requests
        }
        console.error('Vue error:', error);
      };
    }
  });