<script setup lang="ts">
import AbandonedCart from "@/models/AbandonedCart";
import PromoBanner from "~/components/TopBanner/Promos/PromoBanner.vue";
import ReferralBanner from "~/components/TopBanner/Referrals/ReferralBanner.vue";
import PromoCodeTiers from "~/components/TopBanner/Promos/PromoCodeTiers.vue";
import ReferralRedemptionLimitBanner from "~/components/TopBanner/Referrals/ReferralRedemptionLimitBanner.vue";
import {useStaticHeader} from "~/composables/useStaticHeader";
import { ROUTE_MAIN } from "~/constants/routes";


useStaticHeader('.promo-banner', '.lg-top-header-style');

const onboardingStore = useOnboardingStore();
const route = useRoute();

const abandonedCart = computed(() => {
  return new AbandonedCart(onboardingStore.$state);
});

const isVisible = computed(() => {
  if (
    route.name === "onboarding-checkout-success" ||
    route.name === "login" ||
    route.name === "success-partner" ||
    (route.name === "menus" && !abandonedCart.value.hasPlan())
  ) {
    return false;
  }
  return true;
});
</script>
<template>
  <client-only>
    <PromoCodeTiers/>
    <PromoBanner/>
  </client-only>
  <div class="tw-sticky tw-top-0 tw-right-0 tw-left-0 tw-z-50 lg-top-header-style">
    <ReferralBanner/>
    <ReferralRedemptionLimitBanner/>
    <div
      :class="{
        'tw-justify-between max-md:tw-hidden': !abandonedCart.hasPlan(),
      }"
      class="md:tw-pt-[12px] md:tw-pb-2 tw-shadow-nl-5 tw-text-[15px] tw-relative tw-bg-nl-yellow-100 tw-w-full tw-min-h-[70px] tw-flex"
    >
      <nuxt-link
        :to="ROUTE_MAIN"
        :class="['tw-hidden tw-ml-[33px] tw-w-[160px] md:tw-flex']"
      >
        <img src="/assets/images/signup/logo.svg" alt="Nurture Life" />
      </nuxt-link>
      <ClientOnly>
        <RegflowProgressLegacy v-if="isVisible" />
      </ClientOnly>
    </div>
  </div>
</template>
<style scoped>
  .lg-top-header-style {
    --rh: 0px;
    top: calc(var(--rh, 0px));
  }
</style>
