<script setup lang="ts">
import { URL_ACCOUNT } from "@/constants/urls";
import PromoBanner from "~/components/TopBanner/Promos/PromoBanner.vue";
import {useStaticHeader} from "~/composables/useStaticHeader";
import { ROUTE_MAIN } from "~/constants/routes";

const accountUrl = computed(() => {
  return URL_ACCOUNT;
});

useStaticHeader('.promo-banner', '.lg-top-header-style');

</script>
<template>
  <PromoBanner />
  <div class="tw-sticky tw-top-0 tw-right-0 tw-left-0 tw-z-50 lg-top-header-style">
    <header
      id="header"
      class="tw-bg-nl-yellow-100 tw-shadow-nl-3 tw-py-2 sm:tw-pl-[33px] tw-pl-[11px] tw-pr-[15px] sm:tw-pr-[52px] tw-relative after:tw-content-[''] after:tw-fixed after:tw-inset-x-0 after:tw-top-0 after:tw-h-[100vh] after:tw-bg-white/[.61] after:tw-pointer-events-none after:tw-opacity-0 lg:after:tw-content-none after:tw-ease-in after:tw-duration-300"
    >
      <div class="tw-flex tw-items-center tw-justify-between">
        <div
          class="tw-w-[162px] tw-h-[23px] lg:tw-w-[160px] tw-text-[0] tw-mr-4 lg:tw-mr-10"
        >
          <nuxt-link :to="ROUTE_MAIN"
            ><img
              src="/assets/images/signup/logo.svg"
              alt="nurture-life"
              aria-hidden="true"
          /></nuxt-link>
        </div>
        <div
          class="tw-flex tw-items-center tw-flex-row-reverse lg:tw-flex-row tw-gap-x-3 lg:tw-gap-x-6 tw-relative tw-z-1"
        >
          <a
            :href="accountUrl"
            class="tw-text-center tw-pl-[18px] tw-pr-[18px] tw-text-[13px] sm:tw-pl-[21px] sm:tw-pr-[21px] tw-pt-[7px] tw-pb-[8px] sm:tw-text-[16px] tw-uppercase tw-font-semibold tw-text-white tw-bg-nl-violet-100 tw-rounded-lg tw-block sm:tw-inline-block tw-transition-all hover:tw-bg-nl-violet-200 focus:tw-bg-nl-violet-200 tw-min-w-0 lg:tw-inline"
            >My account</a
          >
        </div>
      </div>
    </header>
  </div>
</template>

<style scoped>
.lg-top-header-style {
  --rh: 0px;
  top: calc(var(--rh, 0px));
}
</style>
