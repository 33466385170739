<template>

</template>

<script setup>
import {updateBeamNonProfit} from "@/services/api/onboarding";
import {events} from "@beamimpact/web-sdk/dist/utils";
import {track as trackBeamNonprofitSelected} from "./track/beamNonprofitSelected";
import {
  getSelectedNonProfitId,
  saveSelectedNonProfitId,
} from "@/services/storage";

const onboardingStore = useOnboardingStore();

const listenToBeamChanges = () => {
  window.addEventListener(events.BeamNonprofitSelectEvent.eventName, async (event) => {
    const {selectedNonprofitId, nonprofitName} = event.detail;

    if (getSelectedNonProfitId() !== selectedNonprofitId) {
      await updateBeamNonProfit(selectedNonprofitId, nonprofitName, onboardingStore.abandonedCartId);
      trackBeamNonprofitSelected(selectedNonprofitId, nonprofitName);
      saveSelectedNonProfitId(selectedNonprofitId);
    }
  });
}

onMounted(() => {
  listenToBeamChanges()
});

</script>
