<script setup lang="ts">
useHead({
  link: [
    {
      rel: "stylesheet",
      href: "/assets/compile/tailwind.css",
    },
  ],
});
</script>

<template>
  <div class="tw-min-h-screen tw-flex tw-flex-col tw-bg-nl-yellow-100 tw-antialiased">
    <main
      id="content"
      class="tw-bg-nl-yellow-100 tw-text-nl-green-100 tw-text-body-sm md:tw-text-body tw-flex-grow"
      tabindex="-1"
    >
      <ClientOnly>
        <RegflowHeader />
      </ClientOnly>
      <slot />
    </main>
    <Footer />
  </div>
</template>
<script setup lang="ts"></script>
