interface RouteParams {
    [key: string]: string | number
}

export function useRouteParams() {
    /**
     * Replace route parameters with actual values
     * @param route - Route template with parameters (e.g., "/blog/:category/:slug/")
     * @param params - Object containing parameter values
     * @returns Processed route with replaced parameters
     */
    const replaceParams = (route: string, params: RouteParams): string => {
        let processedRoute = route

        // Replace each parameter in the route
        Object.entries(params).forEach(([key, value]) => {
            processedRoute = processedRoute.replace(`:${key}`, value.toString())
        })

        return processedRoute
    }

    return {
        replaceParams
    }
}