import eventsManager from "@/services/events/eventsManager";

const track = async (selectedNonprofitId: number, selectedNonprofitName: string) => {
  await eventsManager.push("beamNonprofitSelected", {
    env: import.meta.env.VITE_NODE_ENV,
    selectedNonprofitId,
    selectedNonprofitName,
  });
};

export { track };