import {defineStore} from "pinia";
import request from "@/services/request";
import {URL_RETRIEVE_EXPERIMENTS} from "@/constants/urls";
import {track as trackExperimentsLoaded} from "@/services/events/track/experiments/experimentsLoaded";
export const useExperimentStore = defineStore("experiment", {
  state: () => ({
    items: [],
    isLoaded: false,
  }),

  getters: {
    experiments: (state) => state.items,
    hasVariant: (state) => (variantCode: any) =>
      state.items.some((experiment) => experiment.code === variantCode),
    isExperimentsLoaded: (state) => state.isLoaded,
  },

  actions: {
    async getExperiments(params = {}) {
      const onboardingStore = useOnboardingStore();
      const authStore = useAuthStore();
      const finalParams = {
        abandoned_cart_id: onboardingStore.abandonedCartId,
        user_id: authStore.userId,
        ...params,
      };
      const response = await request.get(URL_RETRIEVE_EXPERIMENTS, {
        params: finalParams,
      });
      this.setItems(response.data.data);
      this.isLoaded = true;
      trackExperimentsLoaded(response.data.data);
    },
    setItems(tags) {
      this.items = tags;
    },
  },
});
