import eventsManager from "@/services/events/eventsManager";
import { handle as makeTrackedCart } from "@/cart/track/TrackedCartFromCartFactory";
import type { CartInterface } from "@/cart/CartInterface";

const track = async (
  cart: CartInterface
) => {
  await eventsManager.push("cartUpdated", {
    env: import.meta.env.VITE_NODE_ENV,
    cart: makeTrackedCart(cart),
  });
};

export { track };
