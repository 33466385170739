import eventsManager from "@/services/events/eventsManager";

interface Experiment {
    experiment_name: string;
    code: string;
    id: number;
    name: string;
}

interface TrackedExperiment {
    experimentName: string;
    experimentCode: string;
    experimentId: number;
    name: string;
}

const convertExperimentToTrackedExperiment = (experiments: Experiment[]): TrackedExperiment[] => {
    return experiments.map((experiment) => ({
        experimentName: experiment.experiment_name,
        experimentCode: experiment.code,
        experimentId: experiment.id,
        name: experiment.name,
    }));
};

const track = async (data: Experiment[]) => {
  await eventsManager.push("experimentsLoaded", {
    env: import.meta.env.VITE_NODE_ENV,
    experiments: convertExperimentToTrackedExperiment(data),
  });
};

export { track };