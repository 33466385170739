<script lang="ts" setup>
import {
  MAPPED_ROUTE_NAMES,
  ROUTE_CHECKOUT,
  ROUTE_MENUS,
  ROUTE_NAMES,
} from "@/constants/routes";
import AbandonedCart from "@/models/AbandonedCart";
import MaxPlanThresholdReachedError from "@/models/Exceptions/MaxPlanThresholdReachedError";
import {track as trackBeamNonprofitShown} from "@/components/ExternalServices/Beam/track/beamNonprofitShown";

const onboardingStore = useOnboardingStore();
const { isAnyTopBannerVisible } = storeToRefs(onboardingStore);

const cartStore = useCartStore();
const route = useRoute();
const router = useRouter();
const config = useRuntimeConfig();

const reserveHeight = ref("180px");
const mainSectionRef = ref();
const beamSelectNonrofitRef = ref();
const isChangePlanPopupShow = ref(false);
useHead({
  script: [
    {
      type: "module",
      crossorigin: "anonymous",
      src: "https://production-beam-widgets.beamimpact.com/web-sdk/v1.40.0/dist/components/select-nonprofit.js",
    },
  ],
});

const mainContentSectionHeight = computed(() => {
  return mainSectionRef?.value?.getBoundingClientRect()?.height + "px";
});

async function handleFillCartWithBestsellersClick(id: string) {
  await onboardingStore.FILL_CART_WITH_BESTSELLERS({
    bestsellerGroupId: id,
    sourceUrl: window.location.href,
  });
}

async function handleSubtractProductClick(sku: string) {
  await cartStore.SUBTRACT_PRODUCT({ sku, sourceUrl: window.location.href });
}

async function upgrade(nextPossiblePlan: {
  id: string;
  threshold: number;
  slug: string;
  description: string;
  can_exceed_threshold: boolean;
}) {
  await onboardingStore.UPDATE_PLAN({
    planId: nextPossiblePlan.id,
    sourceUrl: window.location.href,
  });
}

async function autoUpdatePlan() {
  const nextPossiblePlan = onboardingStore.plans.find(
    (item) => item.threshold > Number(currentSelectedPlan?.value?.threshold)
  );

  if (nextPossiblePlan) {
    await upgrade(nextPossiblePlan);
  }

  // Update the url to the new plan
  router.replace({
    query: {
      ...router.currentRoute.value.query,
      plan: nextPossiblePlan?.slug,
    },
  });
}

async function addProduct(sku: string) {
  upgradePlanIfNeeded();
  try {
    await abandonedCart.value.addProduct(
      { sku, quantity: 1 },
      window.location.href
    );
  } catch (error) {
    if (error instanceof MaxPlanThresholdReachedError) {
      return error.dispatchFlahsNotification();
    }
    throw error;
  }
}

function upgradePlanIfNeeded() {
  // if cart items quantity is tied up with selected plan threshold - go upgrade the box

  if (
    (onboardingStore.isCheckoutStepAvailable ||
      cartStore.cartProductsCount >=
        Number(onboardingStore?.selectedPlan?.threshold)) &&
    onboardingStore.largestPlan?.id !== onboardingStore.selectedPlan?.id
  ) {
    autoUpdatePlan();
  } else return;
}

function close() {
  cartStore.UPDATE_CART_STATE({ isOpen: false });
  document.body.classList.remove("cart-box-active");
}

function handleCtaClick() {
  close();
  if (onboardingStore.isCheckoutStepAvailable) {
    return ROUTE_NAMES.includes(route.name as string)
      ? router.push({ name: MAPPED_ROUTE_NAMES.CHECKOUT })
      : (window.location.href = ROUTE_CHECKOUT);
  }
  if (route.name !== MAPPED_ROUTE_NAMES.MENUS) {
    return ROUTE_NAMES.includes(route.name as string)
      ? router.push({ name: MAPPED_ROUTE_NAMES.MENUS })
      : (window.location.href = ROUTE_MENUS);
  }
}

function categoryName(item) {
  let name = item;
  if (item?.startsWith("Finger")) {
    return "Finger Food";
  } else if (item?.startsWith("Kids")) {
    return "Kids Meal";
  }

  return name;
}

const abandonedCart = computed(() => new AbandonedCart(onboardingStore.$state));
const isEmpty = computed(() => cartStore.cartProductsCount === 0);
const targetProductsCount = computed(
  () => onboardingStore?.selectedPlan?.threshold
);

const isOnLargestPlan = computed(
  () => onboardingStore?.selectedPlan?.id === onboardingStore?.largestPlan?.id
);
const isCountDownVisible = computed(() =>
  !onboardingStore.isCheckoutStepAvailable ? true : !isOnLargestPlan.value
);
const remainingToAdd = computed(
  () => Number(targetProductsCount.value) - cartStore.cartProductsCount
);

const canShowCart = computed(() => {
  if (
    route.name === MAPPED_ROUTE_NAMES.SIGNUP ||
    route.name === MAPPED_ROUTE_NAMES.ONBOARDING_PLANS
  )
    return false;

  return cartStore.isCartOpen;
});

const currentSelectedPlan = computed(() => {
  return onboardingStore.selectedPlan;
});

const nextPossiblePlan = computed(() => {
  return onboardingStore.plans.find(
    (item) => item.threshold > Number(currentSelectedPlan?.value?.threshold)
  );
});

onMounted(async () => {
  await onboardingStore.GET_BESTSELLER_GROUPS({
    abandonedCartId: abandonedCart.value.state.abandonedCartId,
  });
});

watch(
  () => beamSelectNonrofitRef.value,
  (newValue) => {
    if (newValue) {
      trackBeamNonprofitShown();
    }
  }
);

const scrollToBottom = () => {
  const element = document.getElementById("autoscroll");
  if (element) {
    element.scrollIntoView({ behavior: "smooth", block: "end" });
  }
};

const bannerForNextDiscountText = computed(() => {
  if (!currentSelectedPlan.value) {
    return null;
  }

  let currentPlanThreshold = currentSelectedPlan.value.threshold;
  let currentCartProductCount = cartStore.cartProductsCount;

  if (
    currentCartProductCount < currentPlanThreshold &&
    currentSelectedPlan.value.discount === 0
  ) {
    let productsLeft = currentPlanThreshold - currentCartProductCount;

    return "ADD " + productsLeft + (productsLeft === 1 ? " ITEM!" : " ITEMS!");
  }

  if (
    nextPossiblePlan.value?.discount > 0 &&
    currentCartProductCount === currentPlanThreshold
  ) {
    return (
      "ADD " +
      (nextPossiblePlan.value.threshold - currentCartProductCount) +
      " ITEMS TO GET $" +
      nextPossiblePlan.value.discount +
      " OFF!"
    );
  }

  if (currentCartProductCount < currentPlanThreshold) {
    return (
      "ADD " +
      (currentPlanThreshold - currentCartProductCount) +
      " ITEMS TO GET $" +
      currentSelectedPlan.value.discount +
      " OFF!"
    );
  }

  return null;
});
</script>
<template>
  <transition
    enter-active-class="tw-ease-out-quad tw-transition-all tw-duration-300"
    leave-active-class="tw-ease-in-quad tw-transition-all tw-duration-200"
    enter-class="tw-md:tw-opacity-0"
    enter-to-class="tw-md:tw-opacity-100"
    leave-class="tw-opacity-100"
    leave-to-class="tw-opacity-0"
  >
    <div
      v-if="canShowCart"
      role="dialog"
      aria-labelledby="modal-cart-title"
      class="max-lg:tw-fixed max-lg:tw-inset-0 max-lg:tw-bg-nl-green-100/[0.35] max-lg:tw-z-50 max-lg:tw-flex max-lg:tw-justify-end lg:tw-sticky"
      :class="{
        'lg:tw-top-[calc(var(--om-floating-height)+72px)]': !isAnyTopBannerVisible,
        'lg:tw-top-[calc(var(--om-floating-height)+128px)]': isAnyTopBannerVisible,
      }"
    >
      <span
        @click="close"
        aria-label="Close Dialog"
        class="icon-main-times tw-text-[12px] tw-cursor-pointer tw-absolute tw-right-[18px] tw-top-[18px] tw-z-10 lg:tw-hidden"
      ></span>
      <div
        class="tw-h-[calc(--vh)] tw-w-[343px] lg:tw-w-[270px] xxl:tw-w-[343px] tw-shadow-nl-10 tw-z-[9] tw-bg-nl-yellow-100 tw-relative"
        :class="{
          'lg:tw-h-[calc(var(--vh)-72px-var(--om-floating-height))]': !isAnyTopBannerVisible,
          'lg:tw-h-[calc(var(--vh)-128px-var(--om-floating-height))]': isAnyTopBannerVisible,
        }"
      >
        <div
          v-if="onboardingStore.selectedPlan"
          class="tw-h-full tw-flex tw-flex-col"
        >
          <div class="tw-flex-1 tw-h-[1%] md:tw-overflow-y-auto md:tw-overflow-x-hidden tw-flex tw-flex-col">
            <div class="max-md:tw-overflow-hidden">
              <div class="tw-p-3 tw-pb-4 tw-text-center tw-shadow-nl-3">
                <strong
                  class="tw-block tw-text-[20px] tw-leading-[1.75] tw-uppercase tw-font-black"
                  >Your Box</strong
                >
                <strong
                  class="tw-block tw-font-black tw-text-[40px] tw-leading-none tw-mb-px"
                >
                  {{ cartStore.cartProductsCount }}
                  <span
                    v-if="
                      cartStore.cartProductsCount <=
                      Number(onboardingStore?.largestPlan?.threshold)
                    "
                    >/{{ targetProductsCount }}</span
                  >
                </strong>
                <div
                  @mouseover="isChangePlanPopupShow = true"
                  @mouseleave="isChangePlanPopupShow = false"
                  class="tw-group/drop tw-relative tw-inline-flex tw-min-w-[153px]"
                >
                  <a
                    href="#"
                    class="tw-inline-flex tw-items-center tw-gap-1 tw-bg-nl-white-100 tw-text-[14px] tw-leading-[1.2] tw-font-medium tw-rounded-[6px] tw-shadow-nl-9 tw-uppercase tw-px-3 tw-py-[3px]"
                  >
                    Change plan size
                    <span
                      class="icon-main-chevron-bold-right tw-inline-block tw-align-center tw-text-[10px] tw-relative tw-transition-all tw-transform tw-mt-px"
                      :class="[
                        isChangePlanPopupShow ? 'tw-rotate-90 tw-mt-0.5' : '',
                      ]"
                    ></span>
                  </a>
                  <ChangePlanPopup
                    v-show="isChangePlanPopupShow"
                    :show="isChangePlanPopupShow"
                    @show="isChangePlanPopupShow = $event"
                  />
                </div>
              </div>
              <a
                v-if="bannerForNextDiscountText"
                href="#"
                :class="{
                  'shake-animation': !(remainingToAdd > 0 && isCountDownVisible),
                }"
                class="tw-block tw-bg-nl-green-100 tw-p-3 tw-text-center"
              >
                <span
                  class="tw-uppercase tw-text-[16px] tw-leading-[1.2] tw-font-bold tw-text-nl-yellow-100"
                  v-text="bannerForNextDiscountText"
                />
              </a>
            </div>
            <div class="max-md:tw-overflow-y-auto max-md:tw-overflow-x-hidden tw-flex-1 max-md:tw-h-[1%]">
              <div
                v-if="cartStore.cartProductsCount < 1"
                class="tw-py-5 tw-px-[39px] tw-text-center tw-text-[16px] tw-leading-none tw-font-medium"
              >
                <h3
                  class="tw-text-[21px] tw-leading-none tw-font-bold tw-mb-[14px]"
                >
                  Not sure where to start?
                </h3>
                <p class="tw-mb-3">
                  Mix & match any combination of items to create an order that
                  fits your family’s need!
                </p>
                <p class="tw-mb-3">
                  Or choose one of the options below and we’ll fill your order
                  with our top-rated favorites by category.
                </p>
              </div>
              <div
                ref="mainSectionRef"
                class="tw-p-2.5 tw-pr-[15px]"
              >
                <div class="tw-flex tw-justify-end">
                  <button
                    v-if="!isEmpty"
                    @click="cartStore.EMPTY_CART"
                    class="tw-inline-block tw-bg-nl-white-100 tw-text-[14px] tw-leading-[1.2] tw-font-medium tw-rounded-[6px] tw-shadow-nl-9 tw-uppercase tw-px-3 tw-py-[3px]"
                  >
                    Empty Box
                  </button>
                </div>

                <div v-if="!isEmpty" class="tw-flex tw-flex-col tw-gap-[9px]">
                  <div
                    v-for="product in cartStore.cartProducts"
                    :key="product.sku"
                    class="tw-flex tw-items-center tw-gap-[11px]"
                  >
                    <div class="tw-flex-1 tw-flex tw-items-center tw-gap-[14px]">
                      <div
                        class="tw-size-[88px] lg:tw-size-[55px] xxl:tw-size-[88px]"
                      >
                        <img
                          :src="product.image['cartMobile']"
                          :alt="product.name"
                          class="tw-w-full tw-h-full tw-object-cover"
                        />
                      </div>
                      <div class="tw-flex-1">
                        <strong
                          class="tw-block tw-text-[16px] tw-leading-none tw-font-black tw-mb-[5px]"
                          >{{ product.name }}</strong
                        >
                        <span
                          class="tw-block tw-text-[14px] tw-leading-[1.07] tw-font-medium"
                          v-if="product?.category?.name"
                          >{{ categoryName(product?.category?.name) }}</span
                        >
                      </div>
                    </div>
                    <div
                      class="tw-shrink-0 tw-w-[100px] lg:tw-w-[60px] xxl:tw-w-[108px] tw-h-[30px] tw-white tw-bg-nl-green-300 tw-rounded-[6px] tw-flex tw-items-center tw-justify-between tw-text-white"
                    >
                      <span
                        @click="handleSubtractProductClick(product.sku)"
                        aria-label="Decrease quantity"
                        class="tw-w-[30px] tw-h-[30px] tw-text-[24px] tw-leading-none tw-flex tw-items-center tw-justify-center tw-cursor-pointer -tw-mt-0.5"
                        >-</span
                      >
                      <span class="tw-text-[16px] tw-leading-none tw-font-bold">{{
                        product.quantity
                      }}</span>
                      <span
                        @click="addProduct(product.sku)"
                        aria-label="Increase quantity"
                        class="tw-w-[30px] tw-h-[30px] tw-text-[24px] tw-leading-none tw-flex tw-items-center tw-justify-center tw-cursor-pointer -tw-mt-0.5"
                        >+</span
                      >
                    </div>
                  </div>
                </div>

                <div
                  v-if="
                    remainingToAdd > 0 &&
                    onboardingStore.bestsellerGroups.length > 0
                  "
                  class="tw-pt-[19px] tw-pb-[10px] tw-flex tw-justify-center tw-items-center"
                >
                  <img
                    src="/assets/images/menu/m-divider.svg"
                    alt="divider"
                    class="tw-w-[294px]"
                  />
                </div>

                <div
                  v-if="
                    remainingToAdd > 0 &&
                    onboardingStore.bestsellerGroups.length > 0
                  "
                  class="tw-text-center tw-pb-5"
                >
                  <strong
                    class="tw-block tw-uppercase tw-text-[16px] tw-leading-[1.2065] tw-font-bold tw-mb-[9px]"
                    >Complete with top-rated meals</strong
                  >
                  <ul
                    class="tw-max-w-[247px] tw-mx-auto tw-flex tw-flex-col tw-gap-[6px]"
                  >
                    <li
                      v-for="bestsellers in onboardingStore.bestsellerGroupsShownOnCart"
                      :key="bestsellers.id"
                    >
                      <a
                        href="#"
                        @click.prevent="
                          handleFillCartWithBestsellersClick(bestsellers.id)
                        "
                        class="tw-block tw-bg-nl-white-100 tw-text-[16px] tw-leading-[1.2] tw-font-medium tw-rounded-[6px] tw-shadow-nl-9 tw-uppercase tw-px-4 tw-py-[7px]"
                      >
                        {{
                          bestsellers.cart_cta
                            ? bestsellers.cart_cta
                            : bestsellers.name
                        }}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div
              v-if="
                route.name !== 'checkout' && cartStore.cartProducts.length > 0
              "
              class="tw-px-1 tw-pt-4 md:tw-pb-4 max-md:tw-shadow-nl-4"
            >
              <beam-select-nonprofit
                ref="beamSelectNonrofitRef"
                :apiKey="config.public.beamApiKey"
                :chainId="config.public.beamChainId"
                :storeId="parseInt(config.public.beamStoreId)"
                :baseUrl="config.public.beamApiUrl"
                debug
                @click="scrollToBottom"
                class="tw-px-2"
              ></beam-select-nonprofit>
              <span id="autoscroll"></span>
            </div>
          </div>

          <div
            class="tw-flex tw-flex-col tw-items-center tw-text-center tw-text-[16px] tw-leading-none tw-font-bold tw-bg-nl-yellow-100 md:tw-shadow-nl-4 tw-py-[9px] tw-uppercase -tw-px-2 tw-gap-[10px]"
          >
            <div class="tw-w-full tw-px-4">
              <button
                @click.prevent="handleCtaClick"
                :disabled="!onboardingStore.isCheckoutStepAvailable"
                :class="{
                  'tw-bg-nl-violet-100':
                    onboardingStore.isCheckoutStepAvailable,
                }"
                class="tw-w-full k tw-rounded tw-bg-[#aa8dce] tw-text-white tw-p-3 tw-max-w-[257px] tw-mx-auto tw-transition-all hover:tw-bg-nl-violet-100 tw-uppercase"
              >
                Checkout
              </button>
            </div>
          </div>
        </div>
        <div
          v-else
          class="tw-flex tw-h-full tw-flex-col tw-items-center tw-px-[25px]"
        >
          <h1
            class="tw-mt-[48px] tw-text-center tw-font-black tw-font-agenda tw-text-[32px] tw-uppercase tw-mb-[3px] tw-leading-9"
          >
            Choose your plan to add to cart!
          </h1>
          <p class="tw-mb-[12px]">The more you get, the more you save!</p>
          <CartSelectPlan class="tw-mb-[29px]" />
          <p class="tw-text-center">
            We’re all about flexibility! Update your plan size, skip weeks, or
            cancel your account any time.
          </p>
        </div>
      </div>
    </div>
  </transition>
</template>

<style scoped>
@keyframes pendulum {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.1);
  }
}

.shake-animation {
  animation: pendulum 1.5s infinite alternate;
}
</style>
